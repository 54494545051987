<template>
    <nav>

        <v-navigation-drawer
            v-model="drawer"
            temporary
            bottom
            app
            
        >
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class='title'>
                        AWAKKERJA
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        Menu List
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list>
                <v-list-item 
                    v-for="(item,i) in itemsOneMenu" 
                    :key="i" 
                    router :to='item.link'>
                    <v-list-item-icon>
                    <v-icon>{{item.action}}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item>
                <v-list-group
                    v-for="item in items"
                    :key="item.title"
                    v-model="item.active"
                    :prepend-icon="item.action"
                    no-action
                >
                    <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                    </template>

                    <v-list-item
                    v-for="child in item.items"
                    :key="child.title"
                    router :to='child.link'
                    >
                    <v-list-item-content>
                        <v-list-item-title v-text="child.title"></v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar
            app
            color="white"
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="text-uppercase grey--text">
                <span class="font-weight-light">Awak</span>
                <span>Kerja</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="logout" text color="grey" right>
                <span>Sign Out</span>
                <v-icon>mdi-exit-to-app</v-icon>
            </v-btn>
        </v-app-bar>
    </nav>
</template>

<script>
export default {

    data : () => ({
        itemsOneMenu : [
            {
                action: 'mdi-view-dashboard',
                //   items: [{ title: 'List Item' }],
                title: 'Dashboard',
                link : '/dashboard',
                multiple : false,
            },
        ],
       items: [
        {
          action: 'mdi-database-plus',
          items: [
              { title: 'Kategori',link:'/masterCategories', },
              { title: 'Pengaturan Iklan',link:'/masterSettingsAds', },
          ],
          title: 'CRUD',
            multiple : true,
        },
        {
          action: 'mdi-check',
          items: [
              { title: 'Verifikasi Iklan',link:'/verificationAd' },
              { title: 'Verifikasi Pengguna',link:'/verificationUsers'}
          ],
          title: 'Verifikasi',
            multiple : true,
        },
        {
          action: 'mdi-cog',
          items: [
              { title: 'Pengaturan Iklan',link:'/settingAds', },
          ],
          title: 'Pengaturan',
            multiple : true,
        },
        /*
        {
          action: 'mdi-silverware-fork-knife',
          items: [
            { title: 'Breakfast & brunch' },
            { title: 'New American' },
            { title: 'Sushi' },
          ],
          title: 'Dining',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'List Item' }],
          title: 'Education',
        },
        {
          action: 'mdi-run',
          items: [{ title: 'List Item' }],
          title: 'Family',
        },
        {
          action: 'mdi-bottle-tonic-plus',
          items: [{ title: 'List Item' }],
          title: 'Health',
        },
        {
          action: 'mdi-content-cut',
          items: [{ title: 'List Item' }],
          title: 'Office',
        },
        {
          action: 'mdi-tag',
          items: [{ title: 'List Item' }],
          title: 'Promotions',
        }, */
      ],
        drawer  : false,
        group   : null
    }),
    watch :{
        group(){
            this.drawer = true
        }
    },
    methods : {
        logout: function () {
        this.$session.destroy()
        // this.$router.push('/')
        location.replace('')
        }
    }
}
</script>