<template>
  <v-app class="grey lighten-4">
        <!-- Start Snackbar -->
    <v-container v-if="(typeof this.$session.get('identity')) !=='string' ">
      <div class="text-center">
        <v-snackbar
          v-model="snackbar"
          :multi-line="multiLine"
        >
          {{message}} 
          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Keluar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>  
    <!-- End Snackbar -->
    <v-container v-if="(typeof this.$session.get('identity')) !=='string' ">
      <v-layout wrap>
        <v-flex sm12 md6 offset-md3>
          <v-card elevation="4" light tag="section">
            <v-card-title>
              <v-layout align-center justify-space-between>
                <h3 class="headline" style="width: 670px;">
                  {{ platformName }}
                </h3>
                <v-flex>
                  <v-img :alt="platformName" contain height="48px" width="150px" position="center right" src="http://sobatjasa.herokuapp.com/static/media/logo-sobatjasa.4713d23b.png">
                  </v-img>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <p>Sign in with your username and password:</p>
              <v-form>
                <v-text-field
                  outline
                  label="Username"
                  type="text"
                  v-model="username"
                  :error-messages="formErrors.username"></v-text-field>
                <v-text-field
                  outline
                  label="Password"
                  type="password"
                  v-model="password"
                  :error-messages="formErrors.password"></v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }">
              <!-- <v-btn color="info">
                Forgot password?
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn color="info" :loading="loading" :large="$vuetify.breakpoint.smAndUp" @click="login">
                <v-icon left>mdi-lock</v-icon>
                Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <!--<v-flex sm12 md6 offset-md3>
          <v-layout align-center justify-space-between>
            <p class="caption my-3">
              <a href="#">Privacy Policy</a>
              |
              <a href="#">Terms of Service</a>
            </p>
            <p class="caption my-3">Powered by <a href="#">StaffCircle</a></p>
          </v-layout>
        </v-flex> -->
      </v-layout>
    </v-container>
    <Navbar v-if="(typeof this.$session.get('identity')) =='string' " />
    <v-main v-if="(typeof this.$session.get('identity')) =='string' ">
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
    <Footer v-if="(typeof this.$session.get('identity')) =='string' "/>

  </v-app> 
   <!-- <v-app :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }" dark id="inspire">


  </v-app> -->
</template>


<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import axios from 'axios'
// import VueRouter from 'vue-router'
export default {
  name: 'App',
  components : {
    Navbar,
    Footer
  },

  data: () => ({
    platformName : "Awak Kerja",
    username : '',
    password : '',
    baseUrl : 'https://api.awakkerja.com/',
    formErrors: [],
    multiLine: true, 
    snackbar : false, 
    message : false, 
    loading : false,
  }),
  methods : {
    login : function(){
      this.loading = true
      let vm = this
      axios.post(this.baseUrl+'public/Api/UD/Auth',{username: this.username,password:this.password})
      .then(function(response){
        if(response.data.status==200){
          vm.snackbar = true
          vm.message = response.data.message
          vm.formErrors = []
          setTimeout(function(){
            vm.$session.start()
            vm.$session.set('jwt', response.data.token)
            vm.$session.set('identity',response.data.identity)
            // router.push({ name: 'user', params: { username: 'erina' } }) In both cases, the router will navigate to the path /user/erina.
          },50)
            vm.$router.push('dashboard')
        }else{
          vm.snackbar = true
          vm.message = response.data.message
          if(response.data.formErrors){
            vm.formErrors = response.data.formErrors
          }else{
            vm.formErrors = []
          }
        }
        vm.loading = false
      })
    },
  }
};
</script>
